// @ts-nocheck
import classNames from 'classnames';
import moment from 'moment-timezone';
import React, {useContext} from 'react';
import {
  HireInitials,
  SubcontractorInitials,
  StyleName,
} from '../HireInitials';
import {CardDivider} from 'common-ui/Cards';
import {useTimezone} from 'common-ui/Hooks';
import {Clock} from 'common-ui/Icon';
import {
  DetailsRowFlex,
  DetailsTableFlex,
} from 'components/Common/DetailsTableFlex';
import DotSeparator from 'components/Common/DotSeparator';
import {TooltipWrapper} from 'components/Common/Tooltip';
import {SidebarForm} from 'components/Sidebar';
import {useDetectMobile, OpenableContext} from 'utils/Hooks';
import {InfoCardTooltipIcon} from './InfoCardTooltip';
import styles from './styles.module.scss';

type DriverInfoModelProps = {
  closeSidebar: () => void,
  hireInfo: {
    firstName: string,
    lastName: string,
    truckNumber?: string,
    licensePlate?: string,
    trailerNumber?: string,
    company?: string,
    phoneNumber?: string,
  },
};

const DriverInfoModel = ({closeSidebar, hireInfo}: DriverInfoModelProps) => {
  return (
    <SidebarForm title="Driver Info" onClose={closeSidebar}>
      <div
        className={styles.sectionHeader}
      >{`${hireInfo.firstName} ${hireInfo.lastName}`}</div>
      <CardDivider />
      <DetailsTableFlex>
        <DetailsRowFlex
          labelStyle={styles.innerDetailsRowContainer}
          label="Parent Company"
        >
          {hireInfo.company}
        </DetailsRowFlex>
        <DetailsRowFlex
          labelStyle={styles.innerDetailsRowContainer}
          label="Truck No."
        >
          {hireInfo.truckNumber}
        </DetailsRowFlex>
        <DetailsRowFlex
          labelStyle={styles.innerDetailsRowContainer}
          label="License Plate"
        >
          {hireInfo.licensePlate}
        </DetailsRowFlex>
        <DetailsRowFlex
          labelStyle={styles.innerDetailsRowContainer}
          label="Trailer No."
        >
          {hireInfo.trailerNumber}
        </DetailsRowFlex>
        <DetailsRowFlex
          labelStyle={styles.innerDetailsRowContainer}
          label="Phone No."
        >
          {hireInfo.phoneNumber}
        </DetailsRowFlex>
      </DetailsTableFlex>
    </SidebarForm>
  );
};

type InfoCardProps = {
  hideIcon?: boolean,
  firstName: string,
  lastName: string,
  truckNumber?: string,
  licensePlate?: string,
  trailerNumber?: string,
  company?: string,
  phoneNumber?: string,
  minutesScheduled?: number,
  minScheduledMinutes?: number,
  maxScheduledMinutes?: number,
  styleName?: StyleName,
  hideName?: boolean,
  enableMinutesScheduled?: boolean,
  declinedEvent?: any,
  scheduleView:any
};

export const InfoCard = ({
  hideIcon,
  firstName,
  lastName,
  truckNumber,
  licensePlate,
  trailerNumber,
  company,
  phoneNumber,
  minutesScheduled,
  minScheduledMinutes,
  maxScheduledMinutes,
  styleName,
  hideName,
  enableMinutesScheduled,
  declinedEvent,
  scheduleView,
}: InfoCardProps) => {
  const {openSidebar, closeSidebar, inListView} = useContext(OpenableContext);

  const good =
    parseInt(minutesScheduled, 10) >= minScheduledMinutes &&
    parseInt(minutesScheduled, 10) <= maxScheduledMinutes;
  const bad = parseInt(minutesScheduled, 10) > maxScheduledMinutes;
  const hireHours = +(minutesScheduled / 60).toFixed(2);
  const benchmarkHours = +(maxScheduledMinutes / 60).toFixed(2);
  const isMobile = useDetectMobile();
  const timezone = useTimezone();
  let timeFormatted = null;
  if (declinedEvent) {
    const time = declinedEvent.happenedAt;
    timeFormatted = moment.tz(time, timezone).format('h:mma');
  }
  const hireInfo = {
    firstName,
    lastName,
    truckNumber,
    licensePlate,
    trailerNumber,
    company,
    phoneNumber,
  };
  return (
    <>
      <div className={styles.infoWrapper}>
        {!hideIcon && (
          <HireInitials
            firstName={!hideName && firstName}
            lastName={!hideName && lastName}
            styleName={styleName}
          />
        )}
        <div
          className={classNames(styles.infoText, {
            [styles.noIcon]: hideIcon && isMobile,
            [styles.noPadding]: hideIcon,
          })}
        >
          <div className={styles.nameAndTooltipContainer}>
            <TooltipWrapper message={`${firstName} ${lastName}${truckNumber ? ` • ${truckNumber}` : ''}`}>
              {!hideName ? (
                <>
                  {inListView || scheduleView ? (
                    <span
                      className={styles.infoDriver}
                      onClick={() =>
                        openSidebar(
                          <DriverInfoModel
                            closeSidebar={closeSidebar}
                            hireInfo={hireInfo}
                          />
                        )
                      }
                      onKeyPress={() =>
                        openSidebar(
                          <DriverInfoModel
                            closeSidebar={closeSidebar}
                            hireInfo={hireInfo}
                          />
                        )
                      }
                      role="button"
                      tabIndex={0}
                    >
                      {`${firstName} ${lastName}`}
                    </span>
                  ) : (
                    `${firstName} ${lastName}`
                  )}
                </>
              ) : null}
              &nbsp;
              <span
                className={classNames(styles.infoTextBold, {
                  [styles.noIcon]: hideIcon && isMobile,
                })}
              >
                {truckNumber}
              </span>
            </TooltipWrapper>
            {declinedEvent && (
              <InfoCardTooltipIcon
                timeDeclined={timeFormatted}
                jobName={declinedEvent.project.name}
                scheduleView={scheduleView}
              />
            )}
          </div>
          <div className={styles.hireExtraInfo}>
            {enableMinutesScheduled && benchmarkHours > 0 && (
              <>
                <div className={styles.hoursScheduled}>
                  <div
                    className={classNames(styles.icon, {
                      [styles.good]: !bad && good,
                      [styles.bad]: bad,
                    })}
                  >
                    <Clock />
                  </div>
                  <span
                    className={classNames(styles.text, {
                      [styles.good]: !bad && good,
                      [styles.bad]: bad,
                    })}
                  >
                    <span>{hireHours}</span>
                    <span>/</span>
                    <span>{benchmarkHours}</span>
                  </span>
                </div>
                <DotSeparator />
              </>
            )}
            {!!company && (
              <div
                className={classNames(
                  styles.infoTextTertiary,
                  styles.infoTextOverflow
                )}
              >
                {company}
              </div>
            )}
          </div>
        </div>
      </div>
      {!!phoneNumber && isMobile && (
        <div className={styles.infoPhoneNumber}>
          <a href={`tel:${phoneNumber}`}>
            <div className={styles.callButton}>Call Driver</div>
          </a>
        </div>
      )}
    </>
  );
};

InfoCard.defaultProps = {
  hideIcon: false,
  truckNumber: '',
  licensePlate: '',
  trailerNumber: '',
  company: '',
  phoneNumber: '',
  minutesScheduled: 0,
  minScheduledMinutes: 0,
  maxScheduledMinutes: 0,
  styleName: 'none',
  hideName: false,
  enableMinutesScheduled: false,
  declinedEvent: null,
};

type SubcontractorInfoCardProps = {
  name: string,
  copy?: number,
  numberHires?: number,
  priority?: string,
  hideIcon?: boolean,
};

const readablePriority = (priority) =>
  priority
    ? `${priority.charAt(0).toUpperCase() +
        priority.slice(1).toLowerCase()} Priority`
    : '';

export const SubcontractorInfoCard = ({
  name,
  copy,
  numberHires,
  priority,
  hideIcon,
}: SubcontractorInfoCardProps) => (
  <div className={styles.infoWrapper}>
    {!hideIcon && <SubcontractorInitials name={name} priority={priority} />}
    <div
      className={classNames(styles.infoText, {
        [styles.noPadding]: hideIcon,
      })}
    >
      <div>
        {name}
        &nbsp;
        {!!copy && !!numberHires && (
          <span className={styles.infoTextBold}>
            {copy}
            &nbsp;of&nbsp;
            {numberHires}
          </span>
        )}
      </div>
      {!!priority && (
        <div className={styles.infoTextSecondary}>
          {readablePriority(priority)}
        </div>
      )}
    </div>
  </div>
);

SubcontractorInfoCard.defaultProps = {
  copy: 0,
  numberHires: 0,
  priority: '',
  hideIcon: false,
};
