import React, { SVGProps } from 'react';

const CircleMinus = (props: SVGProps<SVGSVGElement>) => (
   
  <svg
    width={22}
    height={22}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
     
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.33366 7.2665L5.06333 7.2665C4.65975 7.26852 4.33365 7.59626 4.33366 7.99984C4.33366 8.40757 4.66036 8.73317 5.06333 8.73317L6.00033 8.73317H10.9375C11.341 8.73105 11.667 8.40336 11.667 7.99984C11.667 7.5921 11.3403 7.2665 10.9373 7.2665L10.667 7.2665L8.73366 7.2665H7.26699L5.33366 7.2665ZM8.00033 15.3332C12.0505 15.3332 15.3337 12.05 15.3337 7.99984C15.3337 3.94964 12.0505 0.666504 8.00033 0.666504C3.95013 0.666504 0.666992 3.94964 0.666992 7.99984C0.666992 12.05 3.95013 15.3332 8.00033 15.3332ZM8.00033 13.8665C4.76009 13.8665 2.13366 11.2401 2.13366 7.99984C2.13366 4.7596 4.76009 2.13317 8.00033 2.13317C11.2406 2.13317 13.867 4.7596 13.867 7.99984C13.867 11.2401 11.2406 13.8665 8.00033 13.8665Z"
      fill="#E2164A"
    />
  </svg>
);

export default CircleMinus;
