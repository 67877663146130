// @ts-nocheck
import classNames from 'classnames';
import React, { useState, ReactNode } from 'react';

import { useOutsideClickHandler } from 'utils/Hooks';
import styles from './styles.module.scss';

type DropdownButtonProps = {

  button: ({ dropdown: boolean, setDropdown: (param:boolean) => void }),

  dropdownSection: ({ dropdown: boolean, setDropdown: (param:boolean) => void }),
  dropdownStyle?: string,
  alignLeft?: boolean,
};

const DropdownButton = ({
  button,
  dropdownSection,
  dropdownStyle,
  alignLeft,
}: DropdownButtonProps) => {
  const [dropdown, setDropdown] = useState(false);
  const dropdownRef = React.useRef();
  useOutsideClickHandler(dropdownRef, () => setDropdown(false));

  const containerClass = alignLeft ? styles.dropdownContainerLeftAlign : styles.dropdownContainer;

  return (

    <div className={styles.container} onMouseLeave={() => setDropdown(false)}>

      {button({ dropdown, setDropdown })}

      <div
        className={

          classNames(styles.dropdownContainer, dropdownStyle, {
            [styles.alignLeft]: alignLeft,
            [styles.hidden]: !dropdown,
          })
        }

        ref={dropdownRef}
      >

        {dropdownSection({ dropdown, setDropdown })}
      </div>
    </div>
  );
};

DropdownButton.defaultProps = {
  dropdownStyle: undefined,
  alignLeft: false,
};

export default DropdownButton;
