// @ts-nocheck
import classNames from 'classnames';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Marker } from 'react-map-gl';
import { compose, withProps, withPropsOnChange } from 'recompose';


import { MarkerType, PositionType } from '../types';
import { driverIdentifiers, makeBounds } from '../utils/map.utils';
import { makeMarkers } from '../utils/marker.utils';
import { usePermissions } from 'common-ui/Hooks';
import { useCompanyContext, useHover } from 'utils/Hooks';
import InfoPopupCard from './InfoPopupCard';

import styles from './styles.module.scss';

// eslint-disable-next-line no-underscore-dangle
const defaultPosition = [
  [-85.473633, 45.322461],
  [-73.322754, 41.98527],
];

// Search for a marker that doesn't have bad data

const hasValidMarker = (markers: Array<$Subtype<PositionType>>) => (
  _.some(markers, m => (
    m && !m.badData
  ))
);


const calculateBounds = (markers: Array<$Subtype<PositionType>>) => {
  if (!!markers && markers.length && hasValidMarker(markers)) {
    return makeBounds(markers.filter(m => !m.badData));
  }

  return defaultPosition;
};


export const useLiveMarkerBounds = (markers, openedMarkerID) => {
  const [calcMarkers, setCalcMarkers] = useState([]);
  useEffect(() => {
    if (markers) {

      setCalcMarkers(makeMarkers(markers));
    }
  }, [markers]);

  const openPopup = _.find(calcMarkers, (m) => {
    const { data: { id } } = m;
    return id === openedMarkerID;
  });

  const calculatedBounds = calculateBounds(markers);

  return {
    calculatedBounds,
    calcMarkers,
    openPopup,
  };
};

export const ConnectLiveMarkerControls = compose(
  withPropsOnChange(
    (props, nextProps) => {
      const { markers: oldMarkers } = props;
      const { markers: nextMarkers } = nextProps;

      return !_.isEqual(oldMarkers, nextMarkers);
    },

    ({ markers }) => (
      {
        calcMarkers: markers && makeMarkers(markers),
      }
    ),
  ),

  withProps(({
    markers,
    calcMarkers,
    markerId,
  }) => {
    const calculatedBounds = calculateBounds(markers);

    const openMarker = _.find(calcMarkers, (marker) => {
      const { data: { id } } = marker;
      return id === markerId;
    });

    return {
      openPopup: openMarker,
      calculatedBounds,
    };
  }),
);

type MapContainerPropTypes = {
  markers: Array<MarkerType>,
  toggleMarkerOpen: (param: string | number) => any,
  openMarkerId?: string | number,
  driverIdentifier: string,
};

const bearing = {
  N: 0,
  NE: 45,
  E: 90,
  SE: 135,
  S: 180,
  SW: 225,
  W: 270,
  NW: 315,
};


const getBearingClassName = (rotation) => {
  // If we have an exact rotation value of 0 it's likely there's no rotation info
  if (rotation === 0) return '';
  // Since each bearing is separated by 45deg,
  // we give each direction a margin of 22.5deg
  // Start calculation from the maximum rotation clockwise (360 or 0 deg)
  const margin = 22.5;
  if ((rotation > 360 - margin) || (rotation >= bearing.N && rotation <= bearing.N + margin)) {
    return styles.N;
  }
  for (const dir of Object.keys(bearing)) {

    if (rotation > bearing[dir] - margin && rotation <= bearing[dir] + margin) {
      return styles[dir];
    }
  }
};

const LiveMarkers = (props: MapContainerPropTypes) => {
  const {
    markers,
    // eslint-disable-next-line react/prop-types
    sites,
    openMarkerId,
    toggleMarkerOpen,
    driverIdentifier,
    // eslint-disable-next-line react/prop-types
    selectedSiteId,

    // eslint-disable-next-line react/prop-types
    setSelectedSiteId,
  } = props;

  return (

    <>
      {markers.map(marker => (

        <LiveMarkerIcon
          key={`markerIcon-${marker.id}`}
          marker={marker}
          sites={sites}
          openMarkerId={openMarkerId}
          toggleMarkerOpen={toggleMarkerOpen}
          driverIdentifier={driverIdentifier}
          selectedSiteId={selectedSiteId}
          setSelectedSiteId={setSelectedSiteId}
        />
      ))}
    </>
  );
};

const LiveMarkerIcon = ({

  marker,

  sites,

  openMarkerId,

  toggleMarkerOpen,

  driverIdentifier,

  selectedSiteId,

  setSelectedSiteId,
}:any) => {
  const [ref, isHovered] = useHover();
  const { data: v } = marker;
  const {
    lat, lng, id, rotation, type, initials, truckNumber, activeCycle,
  } = v;
  const popupOpen = id === openMarkerId;
  const activeMarker = popupOpen || (activeCycle && selectedSiteId > 0 && (selectedSiteId === activeCycle.pickupSiteID || selectedSiteId === activeCycle.dropoffSiteID));
  const { showName, showPhoneNumber, showStatus, showTimecard, showLicensePlate } = marker.data;

  let driverText = truckNumber;
  let altDriverText = truckNumber;
  if (showName) {
    driverText = (driverIdentifier === driverIdentifiers.truckNumbers && truckNumber) || initials;
    altDriverText = (driverIdentifier !== driverIdentifiers.truckNumbers && truckNumber) || initials;
  }

  const { selectedCompanyID } = useCompanyContext();
  const { ViewLiveCycleInsights: viewCycles } = usePermissions(selectedCompanyID);
  if (selectedSiteId !== '0' && !activeMarker && viewCycles) {
    return null;
  }
  return (

    <div
      key={id}
      role="button"
      tabIndex={0}
      onClick={() => {
        setSelectedSiteId(null);
        toggleMarkerOpen(id);
      }}
      onKeyPress={() => toggleMarkerOpen(id)}
    >

      <Marker
        longitude={lng}
        latitude={lat}
        key={`marker-${id}`}
      >

        <div
          ref={ref}

          className={classNames(styles.markerContainer, {
            [styles.notAvailable]: !showStatus,
            [styles.idle]: showStatus && (type === 'idle' || type === 'idleDirection'),
            [styles.badData]: showStatus && type === 'badData',
            [styles.lostConnection]: showStatus && type === 'lostConnection',
            [styles.active]: activeMarker,
          })}
        >
           <div className={classNames(styles.markerTextContainer, {
            [styles.hidden]: isHovered,
          })}
          >
            {(!activeMarker && isHovered) && driverText }
             {(!activeMarker && !isHovered) &&  altDriverText }
          </div>
        </div>
        {!activeMarker && (
           <div className={classNames(styles.bearingIndicator, getBearingClassName(rotation), {
            [styles.notAvailable]: !showStatus,
            [styles.idle]: showStatus && (type === 'idle' || type === 'idleDirection'),
            [styles.badData]: showStatus && type === 'badData',
            [styles.lostConnection]: showStatus && type === 'lostConnection',
          })}
          />
        )
      }
      </Marker>
      {(popupOpen || isHovered) && (

        <InfoPopupCard
          key={`popup-${id}`}
          info={v}
          isHovered={!popupOpen && isHovered}
          sites={sites}
          showName={showName}
          showPhoneNumber={showPhoneNumber}
          showStatus={showStatus}
          showTimecard={showTimecard}
          showLicensePlate={showLicensePlate}
        />
      )}
    </div>
  );
};

LiveMarkers.defaultProps = {
  openMarkerId: null,
};

export default LiveMarkers;
