// @ts-nocheck
import _ from 'lodash';
import moment from 'moment';
import 'moment-duration-format';

export const adjustmentGroup = {
  MANUAL: 'MANUAL',
  PRESET: 'PRESET',
};

export const adjustmentTypes = {
  BREAKS: 'BREAKS',
  LUNCH: 'LUNCH',
  TRAVEL_TIME: 'TRAVEL_TIME',
  VEHICLE_BREAKDOWN: 'VEHICLE_BREAKDOWN',
  RETURN_TIME: 'RETURN_TIME',
  VEHICLE_INSPECTION: 'VEHICLE_INSPECTION',
  OTHER: 'OTHER',
  DEFAULT_OTHER: 'DEFAULT_OTHER',
  START_TIME: 'START_TIME',
  END_TIME: 'END_TIME',
  PAUSE_TIME: 'PAUSE_TIME',
  COMMUTE_TIME: 'COMMUTE_TIME',
  UNAUTHORIZED_STOP: 'UNAUTHORIZED_STOP',
  MINIMUM_HOURS: 'MINIMUM_HOURS',
  LATE: 'LATE',
};


export const adjustmentMinutes = _.range(15, 245, 15).reduce((obj: any, minutes: any) => {
  return {
    ...obj,
    [`MINUTES_${minutes}`]: minutes,
  };
}, {});

export const defaultAdjustmentTypes = [
  adjustmentTypes.VEHICLE_INSPECTION,
  adjustmentTypes.RETURN_TIME,
  adjustmentTypes.TRAVEL_TIME,
  adjustmentTypes.DEFAULT_OTHER,
];

export const manualAdjustmentType = {
  SUBTRACT: 'SUBTRACT',
  ADD: 'ADD',
};

export const adjustmentTimes = {
  PRE_TRIP: 'PRE_TRIP',
  POST_TRIP: 'POST_TRIP',
};

export const tripTypes = {
  LOAD: 'LOAD',
};

export const TicketSortTypes = {
  LOAD_TIME: 'LOAD_TIME',
  DRIVER_UPLOAD_TIME: 'DRIVER_UPLOAD_TIME',
};

// Time formating: 12h 30m
// Time formatting: 12h 30m
export const formatDuration = (time: any) => {
  if (!time && time !== 0) {
    return '-';
  }
  return moment.duration(time, 'minutes').format('H [h] mm [m]');
};

export const formatAdjustmentDuration = (time: any) => {
  const timeFormat = 'h[h] mm [m]';
  if (time === 0) {
    return moment.duration(time, 'minutes').format(timeFormat);
  }
  if (time < 0) {
    return ` -(${moment.duration(time * -1, 'minutes').format(timeFormat)})`;
  }
  return `+ ${moment.duration(time, 'minutes').format(timeFormat)}`;
};

// Time formatting: 12hrs 30mins
export const formatDurationLong = (time: any) => {
  if (!time && time !== 0) {
    return '-';
  }
  return moment.duration(time, 'minutes').format('H [hrs] m [mins]');
};

export const formatMinuteDuration = (time: any) => {
  if (time < 0) {
    return ` -(${formatDuration(moment.duration(time * -1, 'minutes'))})`;
  }
  return formatDuration(moment.duration(time, 'minutes'));
};

export const displayType = (type: string, timing: string) => {
  switch (type) {
    case adjustmentTypes.TRAVEL_TIME:
      return 'Travel Time';
    case adjustmentTypes.VEHICLE_INSPECTION: {
      let title = 'Vehicle Inspection';
      if (timing === adjustmentTimes.PRE_TRIP) {
        title = 'Pre-Trip Inspection';
      } else if (timing === adjustmentTimes.POST_TRIP) {
        title = 'Post-Trip Inspection';
      }
      return title;
    }
    case adjustmentTypes.RETURN_TIME:
      return 'Return Time';
    case adjustmentTypes.VEHICLE_BREAKDOWN:
      return 'Vehicle Repair/Breakdown';
    case adjustmentTypes.BREAKS:
      return 'Break';
    case adjustmentTypes.OTHER:
    case adjustmentTypes.DEFAULT_OTHER:
      return 'Other';
    case adjustmentTypes.LUNCH:
      return 'Lunch';
    case adjustmentTypes.COMMUTE_TIME:
      return 'Commute Time (between jobs)';
    case adjustmentTypes.UNAUTHORIZED_STOP:
      return 'Unauthorized Stop';
    case adjustmentTypes.MINIMUM_HOURS:
      return 'Minimum Hours';
    case adjustmentTypes.LATE:
      return 'Late';
    case adjustmentTypes.PAUSE_TIME:
      return 'Pause Time';
    default:
      return '';
  }
};

export const displayMinutes = (minutes: number) => {
  return `${minutes} mins`;
};


export const vehicleInspection = (adjustments: Adjustment[], type: string) => {
  let vehicleInspectionTimeMinutes = 0;
  adjustments.forEach((a) => {
    if (a.type === adjustmentTypes.VEHICLE_INSPECTION) {
      if (a.timing === type) {
        vehicleInspectionTimeMinutes += a.minutes;
      }
    }
  });
  return vehicleInspectionTimeMinutes;
};

export const displayTime = (type: string) => {
  switch (type) {
    case adjustmentTimes.PRE_TRIP:
      return 'Pre-Trip';
    case adjustmentTimes.POST_TRIP:
      return 'Post-Trip';
    default:
      return '';
  }
};

export const TimesheetPages = {
  DAILY_BREAKDOWN: 'DailyBreakdown',
  ADDITIONAL_FIELDS: 'AdditionalFields',
  TIMESHEET_PHOTO_HISTORY: 'TimesheetPhotoHistory',
};

export const getTimesheetToggleOptions = () => {
  const toggleOptions = [];
  // TODO: Add any Permission checks
  toggleOptions.push({
    key: 'toggle-daily-breakdown',
    label: 'Daily Breakdown',
    value: TimesheetPages.DAILY_BREAKDOWN,
  });
  toggleOptions.push({
    key: 'toggle-additional-fields',
    label: 'Additional Fields',
    value: TimesheetPages.ADDITIONAL_FIELDS,
  });
  toggleOptions.push({
    key: 'toggle-photo-history',
    label: 'Timesheet Photo & History',
    value: TimesheetPages.TIMESHEET_PHOTO_HISTORY,
  });
  return toggleOptions;
};
