// @ts-nocheck
import classNames from 'classnames';
import React, { ReactNode } from 'react';
import { Eyeglass } from '../../Icon';
import styles from './styles.module.scss';

const styleNames = {
  currency: 'currency',
  search: 'search',
  measurement: 'measurement',
  minutes: 'minutes',
  integer: 'integer',
  float: 'float',
  positiveFloat: 'positiveFloat',
};

type DecoratorProps = {

  styleName?: $Keys<typeof styleNames> | null,
  decoratorContent?: ReactNode | null,
};

type TextInputProps = DecoratorProps & {
  onChange: (newValue: string) => void,
  disabled?: boolean,
  embedded?: boolean,
  step?: number,
  id?: string,
  disableIncrements?: boolean,
  resetInput?: () => void,
  inputRef?: { current: null | HTMLInputElement },
};


const contentForStyle = (styleName?: $Keys<typeof styleNames> | null) => {
  switch (styleName) {
    case styleNames.currency:
      return '$';
    case styleNames.minutes:
      return 'min(s)';
    case styleNames.search:

      return <Eyeglass />;
    default:
      return null;
  }
};

const InputDecorator = ({
  styleName,
  decoratorContent,
}: DecoratorProps) => (

  <div

    className={classNames(
      styles.inputDecorator,
      {
        [styles.currency]: styleName === styleNames.currency,
        [styles.measurement]: styleName === styleNames.measurement,
        [styles.searchIcon]: styleName === styleNames.search,
        [styles.minutes]: styleName === styleNames.minutes,
      },
    )}
  >
    {decoratorContent || contentForStyle(styleName)}
  </div>
);

InputDecorator.defaultProps = {
  styleName: null,
  decoratorContent: null,
};

const getType = (styleName: string, disableIncrements: any): string => {
  const {
    currency, measurement, integer, float, positiveFloat,
  } = styleNames;
  if (disableIncrements) return 'text';
  switch (styleName) {
    case currency:
    case measurement:
    case integer:
    case float:
    case positiveFloat:
      return 'number';
    default:
      return 'text';
  }
};

const getMin = (styleName: string): string | undefined => {
  const { currency, minutes } = styleNames;
  switch (styleName) {
    case currency:
      return '0';
    case minutes:
      return '0';
    default:
      return undefined;
  }
};

const getMax = (styleName: string): string | undefined => {
  switch (styleName) {
    default:
      return undefined;
  }
};
const isAllowedOperationKey = (key: string) => ['Backspace', 'ArrowLeft', 'ArrowRight', 'Tab']
  .filter((allowedKey) => key === allowedKey).length !== 0;

const isNumber = (key: string) => ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
  .filter((n) => key === n).length !== 0;

const isValidMinusSign = (
  key: string,
  selectionStart: number,
): boolean => key === '-' && selectionStart === 0;

const isValidPeriodSign = (key: string, value: string): boolean => {
  if (key !== '.') { return false; }
  return value.split('.').length === 1;
};

const onKeyDownHandler = (event: any, styleName: string): void => {
  const { float, integer, positiveFloat, minutes, currency } = styleNames;
  const { key } = event;
  const { selectionStart, value } = event.target;

  switch (styleName) {
    case integer:
      if (!(isNumber(key) || isAllowedOperationKey(key) || isValidMinusSign(key, selectionStart))) {
        event.preventDefault();
      }
      break;
    case float:
      if (!(isNumber(key)
          || isAllowedOperationKey(key)
          || isValidMinusSign(key, selectionStart)
          || isValidPeriodSign(key, value))) {
        event.preventDefault();
      }
      break;
    case positiveFloat:
    case currency:
      if (!(isNumber(key)
          || isAllowedOperationKey(key)
          || isValidPeriodSign(key, value))) {
        event.preventDefault();
      }
      break;
    case minutes:
      if (!(isNumber(key)
          || isAllowedOperationKey(key))) {
        event.preventDefault();
      }
      break;
    default:
      break;
  }
};

const TextInput = ({
  styleName,
  onChange,
  embedded,
  decoratorContent,
  disabled,
  step,
  disableIncrements,
  inputRef,
  resetInput,
  ...inputProps
}: TextInputProps) => (

  <div className={classNames(styles.textInput, { [styles.embedded]: !!embedded })}>

    <InputDecorator styleName={styleName} decoratorContent={decoratorContent} />

    <input
      ref={inputRef}
      onKeyDown={(e) => onKeyDownHandler(e, styleName)}
      type={getType(styleName, disableIncrements)}
      step={step}
      min={getMin(styleName)}
      max={getMax(styleName)}

      className={classNames(styles.textInputField, {
        [styles.currency]: styleName === styleNames.currency,
        [styles.search]: styleName === styleNames.search,
        [styles.minutes]: styleName === styleNames.minutes,
        [styles.embedded]: !!embedded,
        [styles.disabled]: !!disabled,
      })}
      {...{
        disabled,
        ...inputProps,
        onChange: (e) => {
          if (resetInput) {
            resetInput();
            return;
          }
          onChange(e.target.value);
        },
      }}
    />
  </div>
);

TextInput.styleNames = styleNames;

TextInput.defaultProps = {
  styleName: null,
  embedded: false,
  decoratorContent: null,
  disabled: false,
  step: undefined,
  id: undefined,
  disableIncrements: false,
  inputRef: undefined,
  resetInput: null,
};

export default TextInput;
